.active {
  background-color: rgba(230, 230, 239, 1) !important;
  /* margin: 0 10px !important; */
  border-radius: 8px !important;
  font-weight: bold !important;
  color: black !important;
}

.active svg {
  color: black !important;
}
.sidebar-links {
  color: rgba(0, 0, 0, 0.54);
  transition: all 0.5s;
}
.sidebar-links:hover {
  padding-left: 40px;
}
a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.54);
  width: 100%;
}
.sublink {
  cursor: pointer;
}

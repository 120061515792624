canvas {
  width: 100% !important;
}

.main-container {
  width: calc(95vw - 270px);
}
/* @media screen and (max-width: 700px) {
  .main-container {
    width: 60%;
  }
}
@media screen and (max-width: 640px) {
  .main-container {
    width: 80%;
  }
} */

@media screen and (max-width: 600px) {
  .main-container {
    width: 100%;
  }
}

/* @media screen and (min-width: 1020px) {
  .main-container {
    width: 90%;
  }
}
@media screen and (max-width: 1020px) {
  .main-container {
    width: 80%;
  }
}

@media screen and (max-width: 940px) {
  .main-container {
    width: 75%;
  }
}

@media screen and (max-width: 900px) {
  .main-container {
    width: 70%;
  }
}

@media screen and (max-width: 860px) {
  .main-container {
    width: 64%;
  }
}

@media screen and (max-width: 820px) {
  .main-container {
    width: 68%;
  }
  @media screen and (max-width: 700px) {
    .main-container {
      width: 60%;
    }
  }
  
} */

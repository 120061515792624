* {
  box-sizing: border-box;
  margin: 0;
}
body {
  background: white !important;
  margin: 0 auto !important;
}

.wrapper {
  width: 90% !important;
  max-width: 1213px !important;
  margin: 0 auto !important;
  padding: 0px !important;
}
